import React, { useEffect, useMemo, useRef, useState } from 'react'

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'

import { sortValues } from '../../../utils'
import { NoValue } from '../../ui'
import { throttle } from '../../utils'

const DEFAULT_LIMIT = 30
const INCREMENT = 50

const SortableTable = ({
  values,
  valueKeys,
  defaultSort,
  useManualValues = false,
  onPaginate,
  onSort,
  defaultAsc = false,
}) => {
  const [sortCategory, setSortCategory] = useState(defaultSort)
  const [sortAsc, setSortAsc] = useState(defaultAsc)
  const [limit, setLimit] = useState(DEFAULT_LIMIT)

  const tableRef = useRef(null)

  const tableValues = useMemo(() => {
    if (useManualValues) return values

    const sortedValues = sortValues(values, sortCategory, sortAsc)
    return sortedValues.slice(0, limit)
  }, [sortCategory, sortAsc, values])

  const handleScroll = () => {
    const container = tableRef.current
    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight - 50
    ) {
      if (onPaginate) onPaginate()
      if (!useManualValues && limit < values.length)
        setLimit(limit =>
          limit + INCREMENT <= values.length ? limit + INCREMENT : values.length
        )
    }
  }
  useEffect(() => {
    if (useManualValues) return
    setLimit(DEFAULT_LIMIT)
  }, [values])

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 200)

    const container = tableRef.current
    container?.addEventListener('scroll', throttledHandleScroll, false)
    return () => {
      container?.removeEventListener('scroll', throttledHandleScroll, false)
    }
  }, [values])

  // Handle sorts
  useEffect(() => {
    if (tableRef.current) tableRef.current.scrollTo({ top: 0 }) // scroll to top of table
    if (onSort) onSort(sortCategory, sortAsc)
  }, [sortCategory, sortAsc])

  const sortByCategory = category => {
    if (sortCategory === category) {
      setSortAsc(prev => !prev)
    } else {
      setSortCategory(category)
      setSortAsc(false)
    }
    setLimit(DEFAULT_LIMIT)
  }

  const headingBg = useColorModeValue('white', 'gray.800')
  const rowHoverColor = useColorModeValue('gray.200', 'gray.600')
  const headerColor = useColorModeValue('gray.500', 'gray.200')

  if (!values || values.length === 0) return null
  return (
    <Box maxH={550} overflowY='auto' ref={tableRef}>
      <Table size='sm'>
        <Thead position='sticky' top='0' bg={headingBg} zIndex='1' h={30}>
          <Tr>
            {valueKeys?.map((key, index) => (
              <Th
                key={index}
                _hover={{ cursor: 'pointer' }}
                onClick={() => sortByCategory(key.value)}
                color={headerColor}
              >
                <Flex gap={1}>
                  <Text>{key.label}</Text>
                  {sortCategory === key.value &&
                    (sortAsc ? <TriangleUpIcon /> : <TriangleDownIcon />)}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableValues?.map((item, index) => (
            <Tr key={index} _hover={{ bg: rowHoverColor }}>
              {valueKeys.map((key, index) => (
                <Td key={index} whiteSpace='nowrap'>
                  {key.onClick ? (
                    <Link
                      onClick={() => {
                        if (key.onClick) key.onClick(item[key.value])
                      }}
                    >
                      {item[key.value]}
                    </Link>
                  ) : key.formatValue instanceof Function ? (
                    key.formatValue(item[key.value])
                  ) : (
                    item[key.value] || <NoValue />
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default SortableTable
