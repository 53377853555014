import React from 'react'

import { Flex, Text, Center, Image } from '@chakra-ui/react'

const NoData = () => {
  return (
    <Center as={Flex} direction='column' p={3}>
      <Image src='/assets/tools/no-data.jpg' alt='No data found' w='400px' />
      <Text variant='subheading-gray' color='red.400' p={5} align='center'>
        No data to generate report during these dates
      </Text>
    </Center>
  )
}
export default NoData
