import React from 'react'

import { Card, CardBody, Text } from '@chakra-ui/react'

import ReportSectionHeading from './ReportSectionHeading'
import SortableTable from './SortableTable'
import { formatSecondsToReadable } from '../../../utils'

const AgencyBreakdown = ({ agencyBreakdown }) => {
  if (!agencyBreakdown || agencyBreakdown.length === 0) return null

  const valueKeys = [
    {
      label: 'Agency Name',
      value: 'agency',
    },
    {
      label: 'Average Time from Open to Closed',
      value: 'average_time',
      formatValue: formatSecondsToReadable,
    },
  ]

  return (
    <Card maxH={750} overflowY='auto'>
      <CardBody>
        <ReportSectionHeading>Agencies</ReportSectionHeading>
        <Text variant='subheading-sm'>
          This section is experimental. Please notify an admin if this data is
          incorrect.
        </Text>
        <SortableTable
          values={agencyBreakdown}
          valueKeys={valueKeys}
          defaultSort='agencies'
        />
      </CardBody>
    </Card>
  )
}
export default AgencyBreakdown
