import React from 'react'

import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import NoData from './NoData'
import ReportSectionHeading from './ReportSectionHeading'
import SortableTable from './SortableTable'
import {
  DEFAULT_CASE_ASC,
  DEFAULT_CASE_SORT,
  paginateCases,
  sortCases,
} from '../../../redux/features/reportSlice'
import { formatOrderBy } from '../../../utils'
import { Loading } from '../../ui'
import { extractCaseNum } from '../../utils'

const CaseworkList = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.reports.cases_loading)
  const cases = useSelector(state => state.reports.cases?.results)
  const generated = useSelector(state => state.reports.cases_generated)
  const paginating = useSelector(state => state.reports.paginate_cases_loading)
  const canPaginate = useSelector(state => state.reports.cases?.next)

  const sort = useSelector(state => state.reports.filters.order_by)
  const valueKeys = [
    {
      label: 'Case Number',
      value: 'case_num',
      onClick: case_num => window.open(`/casework/${extractCaseNum(case_num)}`),
    },
    {
      label: 'Current Status',
      value: 'status',
    },
    {
      label: 'Opened Date',
      value: 'opened_at',
    },
    {
      label: 'Closed Date',
      value: 'closed_at',
    },
    {
      label: 'Last Updated',
      value: 'last_updated',
    },
  ]

  const handlePaginate = () => {
    if (!canPaginate) return
    dispatch(paginateCases())
  }

  const handleSort = (category, asc) => {
    const newSort = formatOrderBy(category, asc)
    if (sort === newSort) return
    dispatch(sortCases(newSort))
  }

  return (
    <Card maxH={625}>
      <CardBody>
        <Flex align='center' pb={1}>
          <ReportSectionHeading w='60%'>Case Breakdown</ReportSectionHeading>
          <Spacer />
          {paginating && (
            <HStack>
              <Text>Loading more cases</Text>
              <Box h={5} w={5}>
                <Spinner />
              </Box>
            </HStack>
          )}
        </Flex>
        <Box pt={2}>
          {loading || (generated && !cases) ? (
            <Loading message={'Lots of cases...loading'} />
          ) : cases?.length === 0 && cases !== null ? (
            <NoData />
          ) : (
            <SortableTable
              values={cases}
              useManualValues={true}
              onPaginate={handlePaginate}
              onSort={handleSort}
              valueKeys={valueKeys}
              defaultSort={DEFAULT_CASE_SORT}
              defaultAsc={DEFAULT_CASE_ASC}
            />
          )}
        </Box>
      </CardBody>
    </Card>
  )
}

export default CaseworkList
