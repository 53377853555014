import React from 'react'

import { Flex, Stack, HStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import AgencyBreakdown from './AgencyBreakdown'
import CaseworkCount from './CaseworkCount'
import CaseworkIssues from './CaseworkIssues'
import CaseworkList from './CaseworkList'
import DateBreakdown from './DateBreakdown'
import IntakeMethods from './IntakeMethods'
import NoData from './NoData'
import StaffBreakdown from './StaffBreakdown'
import { Loading } from '../../ui'

const Report = () => {
  const loading = useSelector(state => state.reports.report_loading)
  const report = useSelector(state => state.reports.report)
  const reportGenerated = useSelector(state => state.reports.report_generated)

  if (loading)
    return <Loading message={'Please be patient...Data processing...'} />
  if (!reportGenerated) return null
  if (!report && reportGenerated) return <NoData />

  return (
    <Stack overflow='hidden'>
      <CaseworkCount {...report} />

      <CaseworkList />

      <CaseworkIssues issues={report.issues_count} />

      <Flex
        justify='center'
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        as={HStack}
        bg='100%'
      >
        <IntakeMethods intakeMethods={report.intake_method_count} />

        <DateBreakdown dateBreakdown={report.date_breakdown} />
      </Flex>

      <StaffBreakdown staffBreakdown={report.staff_breakdown} />
      <AgencyBreakdown agencyBreakdown={report.agencies} />
    </Stack>
  )
}

export default Report
