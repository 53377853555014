import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  HStack,
  Hide,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  getQuarter,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subQuarters,
} from 'date-fns'
import { DayPicker } from 'react-day-picker'
import { useDispatch, useSelector } from 'react-redux'

import ReportIcon from '../../../assets/ReportIcon.svg'
import { districtOptions } from '../../../constants'
import {
  DEFAULT_CASE_ASC,
  DEFAULT_CASE_SORT,
  generateReport,
} from '../../../redux/features/reportSlice'
import {
  clearStaffOptions,
  loadStaffOptions,
} from '../../../redux/features/staffSlice'
import {
  capitalize,
  formatDateToString,
  formatDistrict,
  formatOrderBy,
} from '../../../utils'
import { StaffSelect } from '../../ui/select'
import { getQuarterDates } from '../../utils'
import 'react-day-picker/dist/style.css'

const Section = ({ heading, children }) => (
  <Box align='center'>
    <Text
      mb={1}
      fontSize='lg'
      casing='uppercase'
      borderBottom='1px solid'
      fontWeight='bold'
    >
      {heading}
    </Text>
    {children}
  </Box>
)

const GenerateReportForm = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.reports.report_loading)
  const canViewStaff = user.permissions.includes(
    'casework.view_reports_for_staff'
  )

  const [reportType, setReportType] = useState('monthly')
  let today = new Date()
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth() - 1, 1)
  )
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 0)
  )
  const [assignedStaff, setAssignedStaff] = useState(
    canViewStaff ? undefined : user
  )
  const [district, setDistrict] = useState(
    user.is_admin ? undefined : user.district
  )

  useEffect(() => {
    if (!user.is_admin) generate()
  }, [])

  useEffect(() => {
    if (!canViewStaff) return
    setAssignedStaff(undefined)
    dispatch(clearStaffOptions())
    dispatch(loadStaffOptions({ district: district, value: '' }))
  }, [district])

  // On change to report type, set default start and end date
  useEffect(() => {
    if (!reportType) return
    let today = new Date()
    let start = new Date(today)
    let end = new Date()
    if (reportType === 'weekly') {
      start.setDate(start.getDate() - start.getDay() - 7)
      end = endOfWeek(start)
    } else if (reportType === 'monthly') {
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      end = endOfMonth(start)
    } else if (reportType === 'quarterly') {
      start = startOfQuarter(subQuarters(today, 1))
      end = endOfQuarter(start)
    } else if (reportType === 'yearly') {
      start = startOfYear(today)
      end = endOfYear(today)
    }
    setStartDate(start)
    setEndDate(end)
  }, [reportType])

  const generate = () => {
    const filters = {
      report_type: reportType,
    }

    if (reportType !== 'all-time') {
      filters['start_date'] = formatDateToString(startDate)
      filters['end_date'] = formatDateToString(endDate)
    }

    if (assignedStaff) filters['assigned_staff'] = assignedStaff.id

    if (district) filters['district'] = district

    filters['order_by'] = formatOrderBy(DEFAULT_CASE_SORT, DEFAULT_CASE_ASC)
    dispatch(generateReport(filters))
  }

  const WeekSelector = () => {
    const selectedRange = {
      from: startDate,
      to: endDate,
    }

    const [hoveredRange, setHoveredRange] = useState(null)

    const handleDayMouseEnter = date => {
      setHoveredRange({ from: startOfWeek(date), to: endOfWeek(date) })
    }

    const handleDayMouseLeave = () => {
      setHoveredRange(null)
    }

    const handleDayChange = selectedDate => {
      let start = startOfWeek(new Date(selectedDate))
      let end = endOfWeek(start)

      setStartDate(start)
      setEndDate(end)
    }

    const selectedColor = useColorModeValue('#3a4f6b', '#6d91c2')
    const hoveredColor = useColorModeValue('lightgray', '#6d6f73')

    const modifiersStyles = {
      hovered: {
        backgroundColor: hoveredColor, // Use selected color if the hovered range is the same as the selected range
      },
      selected: {
        backgroundColor: selectedColor, // Light blue background color for selected day
      },
    }
    return (
      <DayPicker
        showOutsideDays
        mode='range'
        fromYear={2016}
        defaultMonth={startDate}
        selected={selectedRange}
        onDayClick={handleDayChange}
        onDayMouseEnter={handleDayMouseEnter}
        onDayMouseLeave={handleDayMouseLeave}
        modifiers={{
          selected: selectedRange,
          hovered: hoveredRange,
        }}
        modifiersStyles={modifiersStyles}
      />
    )
  }
  /** COMPONENT FOR SELECTING DATES */
  const DateSelector = () => {
    if (reportType === 'monthly') {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      const currYear = new Date().getFullYear()
      const startYear = 2016 // earliest councilstat casework

      const years = Array.from(
        { length: currYear - startYear + 1 },
        (_, index) => startYear + index
      )

      return (
        <HStack>
          <Box>
            <Select
              id='monthly-month-select'
              value={months[startDate.getMonth()]}
              onChange={e => {
                let month = months.indexOf(e.target.value)
                let start = new Date(startDate.getFullYear(), month, 1)
                let end = new Date(endDate.getFullYear(), month + 1, 0)

                setStartDate(start)
                setEndDate(end)
              }}
            >
              {months.map((month, index) => (
                <option value={month} key={index}>
                  {month}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Select
              id='monthly-year-select'
              value={startDate.getFullYear()}
              onChange={e => {
                let year = e.target.value
                let start = new Date(year, startDate.getMonth(), 1)
                let end = new Date(year, endDate.getMonth() + 1, 0)
                setStartDate(start)
                setEndDate(end)
              }}
            >
              {years.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </Select>
          </Box>
        </HStack>
      )
    }
    if (reportType === 'quarterly') {
      const currYear = new Date().getFullYear()
      const startYear = 2016 // earliest councilstat casework

      const years = Array.from(
        { length: currYear - startYear + 1 },
        (_, index) => startYear + index
      )

      return (
        <HStack>
          <Box>
            <Select
              id='quarterly-quarter-select'
              value={'Q' + getQuarter(startDate).toString()}
              onChange={e => {
                let { start, end } = getQuarterDates(
                  e.target.value,
                  startDate.getFullYear()
                )
                setStartDate(start)
                setEndDate(end)
              }}
            >
              <option value='Q1'>Q1</option>
              <option value='Q2'>Q2</option>
              <option value='Q3'>Q3</option>
              <option value='Q4'>Q4</option>
            </Select>
          </Box>
          <Box>
            <Select
              id='quarterly-year-select'
              value={startDate.getFullYear()}
              onChange={e => {
                let year = e.target.value
                let start = new Date(year, startDate.getMonth(), 1)
                let end = new Date(year, endDate.getMonth() + 1, 0)
                setStartDate(start)
                setEndDate(end)
              }}
            >
              {years.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </Select>
          </Box>
        </HStack>
      )
    }
    if (reportType === 'yearly') {
      const currYear = new Date().getFullYear()
      const startYear = 2016 // earliest councilstat casework

      const years = Array.from(
        { length: currYear - startYear + 1 },
        (_, index) => startYear + index
      )
      return (
        <Select
          id='yearly-year-select'
          value={startDate.getFullYear()}
          onChange={e => {
            let year = e.target.value
            let start = new Date(year, startDate.getMonth(), 1)
            let end = new Date(year, endDate.getMonth() + 1, 0)
            setStartDate(start)
            setEndDate(end)
          }}
        >
          {years.map((year, index) => (
            <option value={year} key={index}>
              {year}
            </option>
          ))}
        </Select>
      )
    }
    return <Text> Select report type to adjust date range.</Text>
  }

  /** COMPONENT FOR DISPLAYING DATE */
  const DateValue = () => {
    if (reportType === 'weekly') {
      return (
        <Popover>
          <PopoverTrigger>
            <Button variant='outline' size='lg' colorScheme={''}>
              {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <WeekSelector />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    }

    return <DateSelector />
  }

  /** COMPONENT FOR DISPLAYING & SELECTING DISTRICT */
  const District = () => {
    if (!user.is_admin) {
      return <Button variant='unstyled'>{formatDistrict(user.district)}</Button>
    }

    return (
      <Select
        id='district-select'
        placeholder='All'
        value={district}
        onChange={e => setDistrict(e.target.value)}
      >
        {districtOptions.map((district, index) => (
          <option value={district.padStart(2, '0')} key={index}>
            {formatDistrict(district)}
          </option>
        ))}
      </Select>
    )
  }

  const StaffSelector = () => {
    if (!canViewStaff) {
      return <Button variant='unstyled'>{user.full_name}</Button>
    }

    return (
      <Box>
        {assignedStaff ? (
          <Tag size='lg'>
            <TagLabel>{assignedStaff.full_name}</TagLabel>
            <TagCloseButton onClick={() => setAssignedStaff(undefined)} />
          </Tag>
        ) : (
          <StaffSelect
            inputProps={{
              id: 'staff-report-select',
              placeholder: 'Search for specific staff',
            }}
            district={district}
            onSelect={option => setAssignedStaff(option)}
            initialSelected={
              assignedStaff
                ? [
                    {
                      label: assignedStaff.full_name,
                      value: assignedStaff,
                    },
                  ]
                : []
            }
          />
        )}
      </Box>
    )
  }

  /** COMPONENT FOR DISPLAYING & SELECTING REPORT TYPE */
  const ReportType = () => {
    const options = ['weekly', 'monthly', 'quarterly', 'yearly', 'all-time']

    return (
      <Select
        id='report-type-select'
        value={reportType}
        onChange={e => setReportType(e.target.value)}
      >
        {options.map((type, index) => (
          <option value={type} key={index}>
            {capitalize(type)}
          </option>
        ))}
      </Select>
    )
  }

  /** REPORT FORM RENDER */
  return (
    <>
      <Card variant='outline' align='center' direction='row'>
        <Hide below='lg'>
          <Image w={100} src={ReportIcon} p={3} />
        </Hide>
        <CardBody align='center'>
          <Flex
            gap={{ base: 3, lg: 10 }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Section heading='District'>
              <District />
            </Section>
            <Section heading='Report Type'>
              <ReportType />
            </Section>
            {reportType !== 'all-time' && (
              <Section heading='Dates'>
                <DateValue />
              </Section>
            )}
            {district && (
              <Section heading='View for staff'>
                <StaffSelector district={district} />
              </Section>
            )}

            <Spacer />
            <Center>
              <Button onClick={generate} size='lg' isDisabled={loading}>
                Generate
              </Button>
            </Center>
          </Flex>
        </CardBody>
      </Card>
    </>
  )
}

export default GenerateReportForm
