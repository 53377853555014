import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  report: null,
  cases: null,
  filters: {},
  report_loading: false,
  cases_loading: false,
}

export const DEFAULT_CASE_SORT = 'status'
export const DEFAULT_CASE_ASC = false

export const reportSlice = createSlice({
  initialState,
  name: 'reports',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload
    },
    setReportLoading(state, action) {
      state.report_loading = action.payload
    },
    setCasesLoading(state, action) {
      state.cases_loading = action.payload
    },

    clearReport(state) {
      state.report = null
      state.cases = null
      state.report_generated = false
      state.cases_generated = false
    },
    setReport(state, action) {
      state.report_loading = false
      state.report = action.payload
      state.report_generated = action.payload !== null
    },
    setCases(state, action) {
      state.cases = action.payload
      state.report_loading = false
      state.paginate_cases_loading = false
      state.cases_generated = action.payload !== null
    },
    paginateCases(state) {
      state.paginate_cases_loading = true
    },
    sortCases(state) {
      state.loading = true
    },
    generateReport(state) {
      state.report_loading = true
    },
    generateReportCases(state) {
      state.cases_loading = true
    },
    exportReport(state) {
      state.exporting = true
    },
    completeExport(state) {
      delete state.exporting
    },
  },
})

export default reportSlice.reducer

export const {
  setFilters,
  setReportLoading,
  setCasesLoading,
  setReport,
  setCases,
  clearReport,

  paginateCases,
  sortCases,

  generateReport,
  generateReportCases,
  exportReport,
  completeExport,
} = reportSlice.actions
