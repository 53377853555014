import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadExcelFile, getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  clearReport,
  completeExport,
  exportReport,
  generateReport,
  generateReportCases,
  paginateCases,
  setCases,
  setFilters,
  setReport,
  sortCases,
} from '../features/reportSlice'

const REPORTS_ENDPOINT = '/api/casework/reports/'
function* generateReportSaga(action) {
  yield put(clearReport())
  const filters = action.payload

  yield put(setFilters(filters))
  const queryString = getFilterQueryString(filters)

  try {
    const response = yield call(() => {
      return backendAPI.get(REPORTS_ENDPOINT + `?${queryString}`)
    })

    yield put(setReport(response.data))
    yield call(generateReportCasesSaga, { payload: queryString })
  } catch (error) {
    yield put(setReport(null))
    console.error(error)
  }
}

function* generateReportCasesSaga(action) {
  const queryString = action.payload

  try {
    const response = yield call(() => {
      return backendAPI.get(REPORTS_ENDPOINT + 'cases/?' + queryString)
    })
    yield put(setCases(response.data))
  } catch (error) {
    yield put(setCases(null))
    console.error(error)
  }
}

function* paginateCasesSaga() {
  const next = yield select(state => state.reports.cases.next)
  if (!next) return

  const response = yield call(() => {
    return backendAPI.get(next)
  })

  const cases = yield select(state => state.reports.cases)
  yield put(
    setCases({
      ...response.data,
      results: [...cases.results, ...response.data.results],
    })
  )
}

function* sortCasesSaga(action) {
  const filters = yield select(state => state.reports.filters)
  const filterWithSort = {
    ...filters,
    order_by: action.payload,
  }

  const queryString = yield getFilterQueryString(filterWithSort)
  const response = yield call(() => {
    return backendAPI.get(REPORTS_ENDPOINT + 'cases/?' + queryString)
  })
  yield put(setCases(response.data))
  yield put(setFilters(filterWithSort))
}

function* exportReportSaga() {
  try {
    const data = yield select(state => state.reports.report)
    const cases = yield select(state => state.reports.cases)
    if (!data || !cases) return
    const response = yield call(() => {
      return backendAPI.post(
        REPORTS_ENDPOINT + 'export/',
        { ...data, cases },
        {
          responseType: 'arraybuffer',
        }
      )
    })

    yield call(downloadExcelFile, response.data, 'casework_report.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExport())
}

function* reportsAPI() {
  yield takeLatest(generateReport, generateReportSaga)
  yield takeLatest(generateReportCases, generateReportCasesSaga)
  yield takeLatest(paginateCases, paginateCasesSaga)
  yield takeLatest(sortCases, sortCasesSaga)
  yield takeLatest(exportReport, exportReportSaga)
}

export default reportsAPI
