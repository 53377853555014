import React from 'react'

import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { DownloadIcon } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'

import GenerateReportForm from './GenerateReportForm'
import Report from './Report'
import { exportReport } from '../../../redux/features/reportSlice'
import { ExportLoading } from '../../ui'

const Reports = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.reports.loading)
  const exporting = useSelector(state => state.reports.exporting)
  const report = useSelector(state => state.reports.report)

  if (!user?.permissions.includes('casework.view_reports'))
    return <Text>You do not have permission to view reports.</Text>

  return (
    <Box maxW='8xl' mx='auto' p='5'>
      <Box py={2}>
        <GenerateReportForm />
      </Box>

      {report && !loading && (
        <Box align='end'>
          <Button
            leftIcon={<Icon as={DownloadIcon} />}
            colorScheme='blue'
            variant='link'
            onClick={() => dispatch(exportReport())}
          >
            Export as Excel File
          </Button>
        </Box>
      )}

      <Box py={2}>
        <Report />
      </Box>

      <ExportLoading isLoading={exporting} />
    </Box>
  )
}

export default Reports
